import React, {useState, useEffect} from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker} from "react-google-maps"
import './TicketsStyle.css'
import School from './school.png'
import PromoterImg from './promoter.png'
import { HiOutlineTicket } from 'react-icons/hi'
import { FiX } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { BsInstagram, BsWhatsapp } from 'react-icons/bs'
import firebase from '../../Firebase'

function Tickets() {
  const navigate = useNavigate()
  const [promoterData, setPromoterData] = useState([])
  const [activeData, setActiveData] = useState(null)

  useEffect(() => {
    firebase.firestore().collection("promoter").get()
    .then(snap => {
      var temp = []
      snap.forEach(doc => {
        temp.push({
          ...doc.data(),
          id: doc.id
        })
      })
      setPromoterData([...temp])
    })
  }, [])
  

  const renderMarkers = promoterData && promoterData.map((promoter, i) => {
      if(promoter.school == "promoter") {
        return <Marker key={i} icon={PromoterImg} onClick={() => setActiveData(promoter)} position={{ lat: promoter.lat, lng: promoter.lng }} />
      } else {
        return <Marker key={i} icon={School} onClick={() => setActiveData(promoter)} position={{ lat: promoter.lat, lng: promoter.lng }} />
      }
      
  })

  const wapre = "https://api.whatsapp.com/send/?phone="
  const wapost = "&text=Hey+%2C+ich+bin+an+einer+Karte+f%C3%BCr+die+College-Party+interessiert%EF%BF%BD." 

  return (
    <div>
      <div className='map-controls'>
        <div className='back-icon' onClick={() => navigate('/')}>
          <FiX />
        </div>
        <a href="https://www.eventbrite.com/e/college-party-dresden-tickets-620787160107" target="_blank" rel="noreferrer">
          <div className='ticket-icon'>
          <HiOutlineTicket />
          <div>
            <h1>Online Tickets</h1>
            <p>(etwas teurer)</p>
          </div>
          </div>
        </a>
      </div>
      { activeData &&
        <div className='map--info-popup'>
          {activeData.school != "promoter" && <h2>{activeData.school}</h2>}
          {activeData.school != "promoter" && <hr />}
          
          <h3>{activeData.name}</h3>
          <div className='map-cta-wrapper'>
            <a href={activeData.wa ? wapre+activeData.wa+wapost : ""} target="_blank" className={'map-cta map-cta--wa ' + (!activeData.wa && " deactivated")} rel="noreferrer"><BsWhatsapp /> WhatsApp</a>
            <a href={activeData.insta ? "https://www.instagram.com/"+activeData.insta : "#"} target="_blank" className={'map-cta map-cta--insta ' + (!activeData.insta && " deactivated")} rel="noreferrer"><BsInstagram />Instagram</a>
          </div>
          <p className='close-cta' onClick={() => setActiveData(null)}>schließen</p>
        </div>
      }
      <MyMapComponent
        isMarkerShown
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAjDuzjRL1yXkDZEaKYBNrsmtfX1Vf-Kdg"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100vh` }} />}
        mapElement={<div style={{ height: `100vh`, position: 'fixed' }} />}
        renderMarkers={renderMarkers}
      />
    </div>
  )
}

const mapOptions = {
    fullscreenControl: false,
    disableDefaultUI: true
}

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={12}
    defaultCenter={{ lat: 51.055, lng: 13.721 }}
    options={mapOptions}
  >   
  {props.renderMarkers}
  </GoogleMap>
))


export default Tickets
import React from 'react'
import './CountdownStyles.css'
import NPMCountdown from 'react-countdown'

function Countdown({date}) {

    const countdownrenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          return (
              <div className=''>
                  <p className='complete-msg'>LETS PARTY!</p>
              </div>
          )
        } else {
          // Render a countdown
          return (
            <div className='flex'>
                {days}:{hours}:{minutes}:{seconds}
            </div>
          )
        }
    };

  return (
    <NPMCountdown date={date}/>
  )
}

export default Countdown
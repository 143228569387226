import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'
import HomeScreen from './pages/HomeScreen';
import Supporter from './pages/Supporter';
import Tickets from './pages/Tickets';
import Kontakt from './pages/Kontakt';
import Details from './pages/Details';
import firebase from './Utilities/Firebase';
import React, { useEffect } from 'react';
import Impressum from './pages/Impressum';
import Vote from './pages/Vote';


function App() {
  firebase.analytics()
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/support" element={<Supporter />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/details" element={<Details />} />
        <Route path="/vote" element={<Vote />} />
      </Routes>
    </Router>
  );
}

export default App;

import React from 'react'
import  './InfoPageStyles.css'
import { useNavigate } from 'react-router-dom'
import Logo from '../../img/Logo.png'

function InfoPage({children}) {
  const navigate = useNavigate()

  return (
    <div className='page'>
    <div className='page-bg'>
      <img src={Logo} className="header-logo" onClick={() => navigate("/")} />
      <div className='container'>
          {children}
      </div>
    </div>
  </div>
  )
}

export default InfoPage
import React from 'react'
import './SupporterStyles.css'
import InfoPage from '../../components/InfoPage'
import { FiMail } from 'react-icons/fi'
import { FaWhatsapp } from 'react-icons/fa'

function Supporter() {
  
  return (
   <InfoPage>
      <h2 className='text-block-header'>Werde teil des Teams!</h2>
      <p>Ein wesentlicher Bestandteil der College-Party sind die Verkäufer, die an ihren Schulen die Tickets verkaufen.
        <br /><br />
         Als Verkäufer könnt ihr eure Abi- oder Klassenkasse aufbessern, aber auch ein zusätzliches Taschengeld verdienen.
        <br /><br />
         Wir freuen uns auf eure Hilfe und erklären euch alle Details gern persönlich. <br />
         Wir sind selber jung, kontaktiere uns also einfach ungebunden per WhatsApp unter folgender Nummer:
      </p>
      <br />
        <a href='https://api.whatsapp.com/send?phone=4915208849733' className='sec-cta' target='_blank'>
            <FaWhatsapp />
            <p href='#'>+49 1520 8849733 (Sania)</p>
        </a>
      <a href='mailto:kontakt@college-party.de' className='sec-cta' style={{marginTop: '2rem'}}>
            <FiMail />
            <p href='#'>kontakt@college-party.de</p>
        </a>
   </InfoPage>
  )
}

export default Supporter
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'

const firebaseConfig = {
  apiKey: "AIzaSyB4tdv5V_BHeIs22DTfUkozjM2b64icSY8",
  authDomain: "col-party.firebaseapp.com",
  projectId: "col-party",
  storageBucket: "col-party.appspot.com",
  messagingSenderId: "803802398187",
  appId: "1:803802398187:web:4da459218c14a91f1b2dae",
  measurementId: "G-DFZ1HS6VB8"
};

  firebase.initializeApp(firebaseConfig);
  firebase.firestore().enablePersistence().catch(err => {
    console.log(err)
  })
  

  export default firebase;
import React, { useState } from 'react'
import BG from '../../img/bg.png'
import './HomeStyles.css'
import Logo from '../../img/Logo.png'
import Countdown from '../../components/molecules/Countdown'
import { Link } from 'react-router-dom'
import firebase from '../../Firebase'

function HomeScreen() {
  const date = 1695495600000;

  const [input, setInput] = useState({})

  const handleInput = e => {
    setInput(prev => ({...prev, [e.target.name]: e.target.value}))
  }

  const handlesubmit = () => {
    firebase.firestore().collection('promoter').add({
      ...input,
      lat: parseFloat(input.lat) ,
      lng: parseFloat(input.lng)
    })
    .then(() => {
      setInput({
        wa: "",
        lat: "",
        lng: "",
        insta: "",
        name: "",
        school: "",
      })
    })
  }

  return (
    <div className='bg-[#1D252C] min-h-screen bg-center bg-cover' >
      <div className='fixed inset-0' style={{ backgroundImage: `url(${BG})`}} />
      <div className='max-w-6xl m-auto pt-12 text-center container flex flex-col'>
        <img className='w-10/12 md:w-8/12 m-auto' alt="Logo" src={Logo} />
        <p className=' uppercase mt-4 mb-12 font-light text-lg'>"Ein Neues Kapitel"</p>
        <div className='text-white text-3xl border-t border-b p-2 mb-8'>
          <Countdown date={date} />
        </div>
        <div className='flex flex-col w-full gap-4 justify-center'>
          <a href="https://college-party.ticket.io/" target='_blank' className='font-bold text-white p-4 border border-white md:self-center justify-self-center cursor-pointer hover:bg-white hover:text-black transition-all' rel="noreferrer">Tickets kaufen</a>
          <p className=' font-bold text-white p-4 md:self-center justify-self-center cursor-pointer hover:bg-white hover:text-black transition-all'>
              Ergebnis Schul-Voting: <br /><br />
              Alle Schüler der AFBB und vom BSZ Wirtschaft Dr. Zeigner erhalten Zutritt zum VIP-Bereich und 10% Rabatt auf alle Getränke
            </p>
        </div>
        
        <p className='text-left text-sm border-t mt-8 pt-4'>
          College Party Dresden - Ein neues Kapitel 🪩💙<br />
          <br />
          Mit neuem Design und Umsetzungsideen (nach euren Wünschen) wird sich einiges ändern!🕺🏼<br />
          <br />
          🔹 Sa., 23.09.2023<br />
          🔹 Stromwerk (ehem. Kraftwerk Mitte)<br />
          🔹 Einlass ab 21 Uhr, 22 Uhr Start<br />
          <br />
          _______________________________________________<br />
          <br />
          <br />
          🎶 MUSIK auf 2 Floors🎶<br />
          <br />
          • MAIN FLOOR<br />
          HipHop, 2000er, Charts<br />
          Dj Shmdt, Magic Marv<br />
          <br />
          + DU bist DJ (23:30- 0:30) <br />
          Du entscheidest über dein Handy, welches Lied als nächstes läuft<br />
          <br />
          • SECOND FLOOR<br />
          Techno powered by ehrenlos Events<br />
          Juan Alejandro & G-TECH<br /><br />
          ______________________________________________<br />
          <br />
          🎓🏫 SCHULVOTING<br />
          <br />
          Die Schule mit den meisten Votes gewinnt:<br />
          • den KOMPLETTEN VIP Bereich für sich<br />
          • 10% Rabatt auf alle Getränke<br />
          <br />
          <Link className=' underline text-cyan-300' to="/vote">Zur Abstimmung</Link> <br />
          <br />
          _______________________________________________<br />
          <br />
          Typisch College Party:<br />

          🥤 REDCUPS<br />
          Alle Getränke in stylischen Redcups<br />

          🥤 „COLLEGE DRINK“<br />
          Wir haben ein besonderen & günstigen Drink für euch, lasst euch überraschen :)<br />
          <br />
          _______________________________________________<br />
          <br />
          🎫 TICKETS 🎫<br />
          • An allen teilnehmenden Schulen & BSZs oder Online:<br />
          <a className=' underline text-cyan-300' href="https://college-party.ticket.io/n0jqcmpw">college-party.ticket.io</a>
          <br />
          <br />
          • Abendkasse wenn nicht vorher ausverkauft<br />
          <br />
          • VIP (Balkon) ⚜️<br />
          Eigene Lounge, Sitzplätze, Service? Dann schreibt uns gern eine Mail via vip.reservierung@kce-dresden.de oder per WhatsApp unter 0173-3829374 an. Solltet ihr individuelle Wünsche für eure Reservierung haben, lasst es uns wissen und wir finden eine Lösung für euch.<br />
          <br />
          _______________________________________________<br />
          <br />
          ℹ️ Weitere Infos ℹ️<br />
          ・offizielles Ausweisdokument ist verpflichtend<br />
          ・P16 nur mit www.partyzettel.de und volljähriger Begleitperson möglich!<br />
          ・eine volljährige Person kann Begleitperson für bis zu 2 Minderjährige sein<br />
        </p> 
      </div>
        
        {false && <Countdown date={date} />}
        <div className='container'>
      
          {false &&
          <div>
            <input type="text" placeholder="school" name="school" onChange={handleInput} value={input.school} />
            <input type="text" placeholder="name" name="name" onChange={handleInput} value={input.name} />
            <input type="text" placeholder="insta" name="insta" onChange={handleInput} value={input.insta} />
            
            
            <input type="text" placeholder="wa" name="wa" onChange={handleInput} value={input.wa} />
            <input type="text" placeholder="lat" name="lat" onChange={handleInput} value={input.lat} />
            <input type="text" placeholder="lng" name="lng" onChange={handleInput} value={input.lng} />
            <button onClick={handlesubmit}>Send</button>
          </div>}

          

        </div>
        <div className='mt-12 text-center text-gray-300 pb-8'>
          <Link to="/impressum">Impressum</Link>
        </div>
    </div>
  )
}

export default HomeScreen
import React from 'react'
import InfoPage from '../../components/InfoPage'
import { FiMail } from 'react-icons/fi'
import { FaWhatsapp } from 'react-icons/fa'


function Kontakt() {
  return (
    <InfoPage>
        <h2 style={{marginBottom: '.5rem'}}>So erreichst du uns</h2>
        <p>Du hast Fragen oder möchtest Tickets an deiner Schule verkaufen? Für solche und alle weiteren Anliegen kannst du uns gern kontaktieren:</p>
        <br />
        {/*<p>Wir leben im 21. Jahrhundert und sind auch noch vor kurzem in die Schule gegangen. Ihr könnt uns also auch ohne Umstände per WhatsApp kontakieren.</p>
        <a href='https://api.whatsapp.com/send?phone=491755556578&text=Hallo,%20ich%20habe%20eine%20Frage%20zur%20College-Party:' className='sec-cta' target='_blank'>
            <FaWhatsapp />
            <p href='#'>+49 175 5556578 (Marius)</p>
        </a>*/}
        <a href='mailto:kontakt@college-party.de' className='sec-cta' style={{marginTop: '2rem'}}>
            <FiMail />
            <p href='#'>kontakt@college-party.de</p>
        </a>
    </InfoPage>
  )
}

export default Kontakt
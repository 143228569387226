import React from 'react'
import './DetailsStyles.css'
import InfoPage from '../../components/InfoPage'

function Details() {
  
  return (
   <InfoPage>
      <h2 className='text-block-header'>Details & Infos</h2>
      <p>Alle Infos zur College-Party kannst du in wenigen Tagen hier finden!</p>
   </InfoPage>
  )
}

export default Details
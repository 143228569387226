import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import './VotingStyles.css'
import InfoPage from '../../components/InfoPage'
import BG from '../../img/bg.png'
import firebase from '../../Firebase'
import {BsCircle, BsCheckCircleFill} from 'react-icons/bs'
import { FiX } from 'react-icons/fi'

function Vote() {
  const [votedata, setVotedata] = useState({})  
  const [userVote, setUserVote] = useState('')
  const [max, setMax] = useState(0);
  const [newSchoolInput, setNewSchoolInput] = useState('')
  const [instaName, setInstaName] = useState("")
  const [confirm, setConfirm] = useState(false)
  const [selectedSchool, setSelectedSchool] = useState("")
  const [adminMode, setAdminMode] = useState(false)
  const [loadedVotes, setLoadedVotes] = useState([])

  useEffect(() => {
    if(adminMode) {
      firebase.firestore().collection('votes').orderBy('username').onSnapshot(docs => {
        let votes = []
        docs.forEach(doc => {
          votes.push({
            ...doc.data(),
            id: doc.id,
          })
        })
        setLoadedVotes(votes)
      })
    }
  }, [adminMode])
  

  const handleConfirm = () => {
    let schoolId = selectedSchool

    if(userVote === schoolId) {
      return
    }
    
    if(instaName.length < 4) {
      alert("Bitte gib eine gültigen Benutzernamen ein!")
      return;
    }

    if(instaName === "ichbinderAdmin!1") {
      setAdminMode(true)
      return
    }

    const voteDocRef = firebase.firestore().collection('voting').doc('votes')
    firebase.firestore().collection('votes').add({
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      username: instaName,
      votedFor: schoolId,
    }).then(() => {
      if(userVote) {
        voteDocRef.update({
          [userVote]: firebase.firestore.FieldValue.increment(-1),
          [schoolId]: firebase.firestore.FieldValue.increment(1)
        })
        .then(() => {
          localStorage.setItem('votedFor', schoolId);
          setUserVote(schoolId)
        })
      } else {
        voteDocRef.update({
          [schoolId]: firebase.firestore.FieldValue.increment(1)
        })
        .then(() => {
          localStorage.setItem('votedFor', schoolId);
          setUserVote(schoolId)
        })
      }
    })

    setInstaName('')
    setConfirm(false)
    setSelectedSchool("")
  }

  const handleVote = (schoolId) => {
    if(userVote) {
      alert("Du hast bereits abgestimmt, schicke die Seite noch deinen Freunden, damit sie auch abstimen können!")
      return 
    }
    setConfirm(true)
    setSelectedSchool(schoolId)
  }

  const handleSubmitNewSchool = () => {
    if(newSchoolInput.length <3) {
      return
    }
    firebase.firestore().collection('voting').doc('votes').update({
      [newSchoolInput.replace('.', ' ')]: 0,
    })
    .then(() => {
      setNewSchoolInput('')
    })
  }

  useEffect(() => {
    firebase.firestore().collection('voting').doc('votes').onSnapshot(snap => {
      if (snap.exists) {
        const data = snap.data();
        let tempMax = 0;
        Object.values(data).forEach(num => {
          if(num > tempMax) {
            tempMax = num
          }
        })
        setMax(tempMax)
        setVotedata({...data})
      }
    })

    const existingVote = localStorage.getItem('votedFor');
    if (existingVote) setUserVote(existingVote);
  }, [])

  const verifyVote = (vote) => {
    firebase.firestore().collection('votes').doc(vote.id).update({
      verified: true,
    })
  }

  const deleteVote = (vote) => {
    firebase.firestore().collection('votes').doc(vote.id).update({
      verified: 'deleted',
    })


    firebase.firestore().collection('voting').doc('votes').update({
      [vote.votedFor]: firebase.firestore.FieldValue.increment(-1),
    })
  }

  const getPercentage = (p) => {
    let x = (p+2.5) * 0.4;
    let mutliplier = 50;
    return (Math.log(x) / Math.log(10) * mutliplier)
  }
  
  
  return (
    <div className='bg-[#1D252C] min-h-screen bg-center bg-cover' >
      <div className='fixed inset-0' style={{ backgroundImage: `url(${BG})`}} />
      <div className='max-w-6xl m-auto pt-12 text-center container flex flex-col'>
      {adminMode && <div className='border border-red-400 p-4'>
        <p>Admin ansicht, letzt anfragen</p>
        {loadedVotes.filter(a => (a.verified !== true && a.verified !=='deleted')).map(vote => (
          <div className='bg-white flex items-center border-b px-1 py-2'>
            <p className='text-black font-bold'>{vote.username}</p>
            <p className='text-gray-400 font-light ml-4'>{vote.votedFor}</p>
            <p onClick={() => verifyVote(vote)} className='text-black ml-auto mr-8 cursor-pointer'>Approve</p>
            <p onClick={() => deleteVote(vote)} className='text-red-800 cursor-pointer'>Delete</p>
          </div>
        ))}
        <p>Alle Insta Namen:</p>
        <div className='text-white'>{loadedVotes.filter(a => a.verified === true).map(v => v.username+' , ')}</div>
      </div>}
      <h1 className='text-2xl font-bold'>Vote für deine Schule</h1>
      <p className='text-gray-300 font-light mb-8'>Alle Schüler der Schule mit den meisten Votes erhalten den gesamten VIP-Bereich für sich allein!</p>
      {
      Object.entries(votedata)
      .sort(([schoolId, votes],[bschoolId, bvotes]) => bvotes - votes)
      .map(([schoolId, votes]) => {
        return (
          <div style={{width:'100%', marginBottom: '1rem', display: 'flex', alignItems: 'center'}} onClick={() => handleVote(schoolId)}>
            <div style={{color: 'white', fontSize: '1.3rem', marginRight: '0.5rem'}}>
              {userVote === schoolId ?
              <BsCheckCircleFill />
              :
              <BsCircle />
              }
            </div>
            <div style={{flex: 1}}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <p>{schoolId}</p>
                <p>{adminMode && votes}</p>
              </div>
              <div className='progressBar--holder'>
                <div className='progressBar' style={{ width: `${getPercentage((votes/max)*100)}%`}} />
              </div>
            </div>
          </div>
        )
      })
      }
      {false && <input className='addInput' placeholder='Hier name eingeben' value={newSchoolInput} onChange={e => (setNewSchoolInput(e.target.value))} />}
      <br />
      
      {false && <button className='cta' onClick={() => handleSubmitNewSchool()}>Schule hinzufügen</button>}
      </div>
      {confirm &&
        <div className='fixed inset-0 bg-black/50 flex justify-center items-center'>
        <div className='rounded-lg bg-white p-4 text-black flex flex-col mx-2' >
          <FiX className=' self-end text-xl' onClick={() => {setConfirm(false); setSelectedSchool(""); setInstaName("")}} />
          <p className='text-black text-2xl font-bold'>Vote bestätigen</p>
          <p className=' font-light text-gray-600'>Bitte gib deinen Instagram-Namen ein, um dein Vote für <b>{selectedSchool}</b> zu bestätigen:</p>
          <input value={instaName} onChange={(e) => setInstaName(e.target.value)} className='border border-gray-500 p-2 rounded mt-4 mb-4' placeholder='Dein Instagram-Name' />
          <button onClick={handleConfirm} className=' bg-black rounded-lg text-white py-2 px-6'>Bestätigen</button>
        </div>
      </div>
      }
   </div>
  )
}

export default Vote